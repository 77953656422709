@-webkit-keyframes animate-svg-fill-1 {
    0% {
        fill: rgba(0, 0, 0, 0);
    }

    50% {
        fill:url(#Gradient2);
    }

    100% {
        fill: rgba(0, 0, 0, 0);
    }
}

@keyframes animate-svg-fill-1 {
    0% {
        fill: rgba(0, 0, 0, 0);
    }

    50% {
        fill: rgb(0, 0, 0);
    }

    100% {
        fill: rgba(0, 0, 0, 0);
    }
}

.svg-elem-1 {
    -webkit-animation: animate-svg-fill-1 0.55s ease-in-out 0s both;
    animation: animate-svg-fill-1 0.55s ease-in-out 0s both;
    animation-iteration-count: infinite;
}

@-webkit-keyframes animate-svg-fill-2 {
    0% {
        fill: rgba(0, 0, 0, 0);
    }

    50% {
        fill: rgb(0, 0, 0);
    }

    100% {
        fill: rgba(0, 0, 0, 0);
    }
}

@keyframes animate-svg-fill-2 {
    0% {
        fill: rgba(0, 0, 0, 0);
    }

    50% {
        fill: rgb(0, 0, 0);
    }

    100% {
        fill: rgba(0, 0, 0, 0);
    }
}

.svg-elem-2 {
    -webkit-animation: animate-svg-fill-2 0.55s ease-in-out 0s both;
    animation: animate-svg-fill-2 0.55s ease-in-out 0s both;
    animation-iteration-count: infinite;

}

@-webkit-keyframes animate-svg-fill-3 {
    0% {
        fill: rgba(0, 0, 0, 0.08);
    }

    25% {
        fill: rgb(0, 0, 0);
    }

    50% {
        fill: rgba(0, 0, 0, 0);
    }

    100% {
        fill: rgba(0, 0, 0, 0);
    }
}

@keyframes animate-svg-fill-3 {
    0% {
        fill: rgba(0, 0, 0, 0);
    }

    25% {
        fill: rgb(0, 0, 0);
    }

    50% {
        fill: rgba(0, 0, 0, 0);
    }

    100% {
        fill: rgba(0, 0, 0, 0);
    }
}

.svg-elem-3 {
    -webkit-animation: animate-svg-fill-3 1.1s ease-in-out 0s both;
    animation: animate-svg-fill-3 1.1s ease-in-out 0s both;
    animation-iteration-count: infinite;

}

@-webkit-keyframes animate-svg-fill-4 {
    0% {
        fill: rgba(0, 0, 0, 0);
    }

    50% {
        fill: rgba(0, 0, 0, 0);
    }

    75% {
        fill: rgb(0, 0, 0);
    }

    100% {
        fill: rgba(0, 0, 0, 0);
    }
}

@keyframes animate-svg-fill-4 {
    0% {
        fill: rgba(0, 0, 0, 0);
    }

    50% {
        fill: rgba(0, 0, 0, 0);
    }

    75% {
        fill: rgb(0, 0, 0);
    }

    100% {
        fill: rgba(0, 0, 0, 0);
    }
}

.svg-elem-4 {
    -webkit-animation: animate-svg-fill-4 1.1s ease-in-out 0s both;
    animation: animate-svg-fill-4 1.1s ease-in-out 0s both;
    animation-iteration-count: infinite;
}

@-webkit-keyframes animate-svg-fill-5 {
    0% {
        fill: rgba(0, 0, 0, 0.1);
    }

    50% {
        fill: rgb(0, 0, 0);
    }

    100% {
        fill: rgba(0, 0, 0, 0.1);
    }
}

@keyframes animate-svg-fill-5 {
    0% {
        fill: rgba(0, 0, 0, 0.1);
    }

    50% {
        fill: rgb(0, 0, 0);
    }

    100% {
        fill: rgba(0, 0, 0, 0.1);
    }
}

.svg-elem-5 {
    -webkit-animation: animate-svg-fill-5 0.55s ease-in-out 0s both;
    animation: animate-svg-fill-5 0.55s ease-in-out 0s both;
    animation-iteration-count: infinite;
}

@-webkit-keyframes animate-svg-fill-6 {
    0% {
        fill: rgba(0, 0, 0, 0.2);
    }

    50% {
        fill: rgb(0, 0, 0);
    }

    100% {
        fill: rgba(0, 0, 0, 0.2);
    }
}

@keyframes animate-svg-fill-6 {
    0% {
        fill: rgba(0, 0, 0, 0.2);
    }

    50% {
        fill: rgb(0, 0, 0);
    }

    100% {
        fill: rgba(0, 0, 0, 0.2);
    }
}

.svg-elem-6 {
    -webkit-animation: animate-svg-fill-6 1.1s ease-in-out 0s both;
    animation: animate-svg-fill-6 1.1s ease-in-out 0s both;
    animation-iteration-count: infinite;
}